import styled from 'styled-components';

import Card from '@mui/material/Card';

export const StyledCard = styled(Card)`
   margin: 0.5rem;

   & .header {
      margin-bottom: 1rem;

      display: flex;
      justify-content: space-between;
   }
`;

/**Div exibição de empresas do produto selecionado */
export const CompaniesAccordionDiv = styled.div`
   p {
      margin-top: 18px;
      font-size: 12px;
      font-weight: 400;

      @media (max-width: 960px) {
         font-size: 9.6px;
      }
   }

   div {
      margin-bottom: 10px;
      margin-top: 6px;
      display: flex;
      justify-content: flex-start;
      max-width: 100vw;
      flex-wrap: wrap;

      img {
         height: 20px;
         margin-top: 2px;

         @media (max-width: 960px) {
            height: 15px;
         }
      }

      img:not(:first-child) {
         margin-left: 20px;
      }
   }
`;
