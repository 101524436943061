import styled from 'styled-components';

/**Container do corpo */
export const StyledBody = styled.div``;

/**Container do rodapé */
export const StyledFooter = styled.div`
   margin: 1rem 0;

   display: flex;
   justify-content: center;

   text-align: center;

   color: var(--color-secondary);

   & img {
      margin: 0.25rem 0 0.5rem 0;
   }

   @media (max-width: 600px) {

      & img {
         width: 4rem;
      }
   }
`;

/**Lista de accordions */
export const StyledList = styled.ul`
   display: grid;
   grid-template-columns: 1fr 1fr 0.75fr;

   @media (max-width: 1024px) {
      grid-template-columns: 1fr;
   }
`;
