import { createGlobalStyle } from 'styled-components';

import { createTheme } from '@mui/material/styles';

/**Estilização global */
export const GlobalStyle = createGlobalStyle`
    :root {
        --color-primary-darker: #005C00;
        --color-primary-dark: #007800;
        --color-primary: #00B700;
        --color-primary-light: #7DDD00;
        --color-primary-lighter: #E4D700;

        --color-secondary-darker: #000000;   
        --color-secondary-dark: #858585;
        --color-secondary: #A6A6A6;
        --color-secondary-light: #C4C4C4;
        --color-secondary-lighter: #E0E0E0;

        --color-negative: #D14A1F;
        --color-positive: #00B700;

        --text-font-family: 'Radio Canada', Tahoma;
    }

    body {
        background: #F5F5F5;
    }

    * {
        box-sizing: border-box;

        margin: 0;
        padding: 0;

        font-family: var(--text-font-family) !important;
    }

    a {
        text-decoration: none;

        color: inherit;
    }

    ul {
        list-style: none;
    }
`;

/**Tematização dos componentes do MUI */
export let theme = createTheme({});
theme = createTheme({
   components: {
      MuiButton: {
         styleOverrides: {
            root: {
               height: '3.5rem',

               display: 'flex',
               alignItems: 'center',

               borderRadius: '0.5rem',

               textTransform: 'none',
               fontWeight: 600,

               color: 'var(--color-text)',

               '&:disabled': {
                  color: 'var(--color-secondary)',
                  background: 'var(--color-secondary-lighter)',
                  boxShadow: 'none',

                  cursor: 'not-allowed',

                  '&:hover': {
                     color: 'var(--color-secondary)',
                     background: 'var(--color-secondary-lighter)',
                     boxShadow: 'none',

                     cursor: 'not-allowed',
                  },
               },
            },

            containedSuccess: {
               border: 'none',

               boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',

               color: 'var(--color-primary-darker)',
               background: 'var(--color-primary)',

               '&:hover': {
                  background: '#009E00',
               },
            },

            outlinedSuccess: {
               borderColor: 'var(--color-positive)',

               '&:hover': {
                  background: '#edffed',
               },
            },

            outlinedError: {
               borderColor: 'var(--color-negative)',

               '&:hover': {
                  background: '#FDF7F7',
               },
            },
         },
      },

      MuiTooltip: { styleOverrides: { tooltip: { textAlign: 'justify' } } },

      MuiTextField: {
         styleOverrides: {
            root: {
               '& label.Mui-focused': {
                  color: 'var(--color-primary)',
               },
               '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                     borderColor: 'var(--color-primary)',
                  },
               },
            },
         },
      },

      MuiList: { styleOverrides: { root: { margin: '1rem 0', padding: 0 } } },

      MuiListItem: { styleOverrides: { root: { margin: '1rem  0', padding: 0 } } },

      MuiStepLabel: {
         styleOverrides: {
            iconContainer: {
               borderRadius: '50%',

               '&.Mui-active': {
                  background: 'var(--color-primary)',
               },
               '&.Mui-completed': {
                  background: 'var(--color-primary)',
               },

               '&.Mui-disabled': {
                  background: 'var(--color-secondary-lighter)',
               },
            },
            label: {
               color: 'var(--color-secondary-lighter)',

               '&.MuiStepLabel-alternativeLabel': {
                  margin: '0.5rem',
               },

               '&.Mui-active': {
                  color: 'var(--color-primary)',
               },
               '&.Mui-completed': {
                  color: 'var(--color-primary)',
               },
            },
         },
      },

      MuiStepIcon: { styleOverrides: { root: { fill: 'green' } } },

      MuiStepConnector: {
         styleOverrides: {
            root: {
               height: '0.1rem',

               '&.Mui-active': { background: 'var(--color-primary)' },
               '&.Mui-completed': { background: 'var(--color-primary)' },
            },
         },
      },

      MuiAccordion: {
         styleOverrides: {
            root: {
               color: 'var(--color-text)',

               boxShadow: '0px 2px 8px 0px rgba(99, 99, 99, 0.2)',

               '&.Mui-disabled': {
                  background: 'rgba(255, 255, 255, 0.5)',

                  cursor: 'not-allowed',
               },

               '&.Mui-expanded': {
                  margin: '0.5rem',
               },
            },
         },
      },

      MuiAccordionSummary: {
         styleOverrides: {
            content: {
               height: '3rem',

               padding: '0',

               display: 'flex',
               justifyContent: 'space-between',
               alignItems: 'center',

               [theme.breakpoints.down('sm')]: {
                  fontSize: '0.8rem',
               },
            },
         },
      },

      MuiAccordionDetails: {
         styleOverrides: {
            root: {
               margin: '0.5rem',
               padding: '0 2rem 2rem',

               [theme.breakpoints.down('sm')]: {
                  fontSize: '0.8rem',
                  padding: '0 0.8rem 2rem',
               },
            },
         },
      },

      MuiCollapse: { styleOverrides: { root: { marginBottom: '0.5rem' } } },

      MuiTabs: {
         styleOverrides: { indicator: { background: 'var(--color-primary)' } },
      },

      MuiTab: {
         styleOverrides: {
            root: {
               border: '1px solid var(--color-secondary)',
               borderRadius: '0.25rem',

               boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.05)',

               '&.Mui-selected': { color: 'var(--color-primary)' },
            },
         },
      },
   },
});
