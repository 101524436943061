import { FC } from 'react';

import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { useProduct } from 'src/shared/contexts/Product';

import { ICompletedAccordion } from 'src/shared/interfaces/accordions';

import { StyledCard } from './completed.style';

const CustomCompletedAccordion: FC<ICompletedAccordion> = ({
   id,
   title,
   content,
   extraContent,
   button,
   setAccordions,
   setActiveStep,
}) => {
   const { setAcquiredProducts } = useProduct();

   return (
      <StyledCard>
         <CardContent>
            <div className="header">
               <Typography variant="h6">{title}</Typography>

               {button !== '' && (
                  <Button
                     size="small"
                     onClick={() => {
                        id === 3 && setAcquiredProducts([]);

                        setAccordions((prev) =>
                           prev.map((acc) =>
                              acc.id === id
                                 ? { ...acc, expanded: true, completed: false, disabled: false }
                                 : { ...acc }
                           )
                        );

                        setActiveStep(id - 1);
                     }}
                  >
                     <Typography variant="button" color="GrayText">
                        {button}
                     </Typography>
                  </Button>
               )}
            </div>

            {content.map((con, index) => (
               <Typography key={index} variant="caption" component="div" color="GrayText">
                  {con}
               </Typography>
            ))}

            {/* {extraContent && (
               <CompaniesAccordionDiv>
                  <p>Alunos dessa e das últimas turmas trabalham nas seguintes empresas:</p>
                  <div>
                     {extraContent.map(({ image_link }, index) => (
                        <img src={image_link} alt="Icone empresa" key={index}></img>
                     ))}
                  </div>
               </CompaniesAccordionDiv>
            )} */}
         </CardContent>
      </StyledCard>
   );
};

export default CustomCompletedAccordion;
