import React from 'react';

import ReactDOM from 'react-dom/client';

import { ThemeProvider } from '@mui/material/styles';

import { ToastProvider } from './shared/contexts/Toast';
import { PersonProvider } from 'src/shared/contexts/Person';
import { ProductProvider } from 'src/shared/contexts/Product';
import { PaymentProvider } from 'src/shared/contexts/Payment';

import { GlobalStyle, theme } from 'src/shared/styles/global.style';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
   <React.StrictMode>
      <ThemeProvider theme={theme}>
         <ToastProvider>
            <PaymentProvider>
               <PersonProvider>
                  <ProductProvider>
                     <GlobalStyle />
                     <App />
                  </ProductProvider>
               </PersonProvider>
            </PaymentProvider>
         </ToastProvider>
      </ThemeProvider>
   </React.StrictMode>
);
