import { FC, Dispatch, SetStateAction } from "react";

import CloseIcon from "@mui/icons-material/Close";

import { StyledModal, Content } from "./style";

const TermsModal: FC<{
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  cryptedProductTypeId: string | false;
}> = ({ open, setOpen, cryptedProductTypeId }) => {
  let src = "Termos e Condições.pdf";

  if (
    cryptedProductTypeId &&
    cryptedProductTypeId === "c5d88545973bb9108c108b72ab918570"
  ) {
    src =
      "https://igtieadstorage.blob.core.windows.net/pdfs/[Regulamento]%20TRIAL%20e%20BOOTCAMP%20PASS%2005_2024.docx.pdf";
  } else if (
    cryptedProductTypeId &&
    cryptedProductTypeId === "e3aa9678236a8e7da440ed7cd1051f10"
  ) {
    src =
      "https://igtieadstorage.blob.core.windows.net/pdfs/[CLP (Mercado Financeiro)] Termo Checkout.pdf";
  } else if (
    cryptedProductTypeId &&
    cryptedProductTypeId === "a7cf7773c5a25c1912351bcad4b9978d"
  ) {
    src = "https://igtieadstorage.blob.core.windows.net/pdfs/Regulamento%20Cursos%20Livres.pdf";
  }

  return (
    <StyledModal open={open} onClose={() => setOpen(false)}>
      <Content>
        <button onClick={() => setOpen(false)}>
          <CloseIcon />
        </button>

        <iframe id="frame" title="Termos e Condições" src={src} />
      </Content>
    </StyledModal>
  );
};

export default TermsModal;
