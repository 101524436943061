/** Arquivo de configuração do axios. */

import axios from 'axios';

/**Função que seleciona aleatoriamente uma máquina para receber as requisições (desafogar o fluxo de acessos) */
const random = () => {
   const value = Math.floor(Math.random() * 4);

   let randomBaseUrl;

   switch (value) {
      case 0:
         randomBaseUrl = process.env.REACT_APP_CHECKOUT_BASE_URL0;
         break;

      case 1:
         randomBaseUrl = process.env.REACT_APP_CHECKOUT_BASE_URL1;
         break;

      case 2:
         randomBaseUrl = process.env.REACT_APP_CHECKOUT_BASE_URL2;
         break;

      case 3:
         randomBaseUrl = process.env.REACT_APP_CHECKOUT_BASE_URL2;
         break;

      default:
         randomBaseUrl = process.env.REACT_APP_CHECKOUT_BASE_URL0;
         break;
   }

   return randomBaseUrl;
};

/**URL base e cabeçalhos para requisições na API base do checkout */
const baseAPI = axios.create({
   baseURL: random(),
});
const baseHeaders = () => {
   return { Authorization: `Bearer ${sessionStorage.getItem('@checkout_token@')}` };
};

export { baseAPI, baseHeaders };
