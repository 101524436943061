import { FC } from 'react';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Circle from '@mui/icons-material/Circle';
import Check from '@mui/icons-material/Check';

import { Container } from './style';

const CustomHeader: FC<{ activeStep: number }> = ({ activeStep }) => {
   /**Array mantendo todos os passos exibidos no cabeçalho */
   const steps = ['Dados Pessoais', 'Endereço', 'Curso', 'Termos', 'Pagamento'];

   return (
      <Container>
         <img src="logo-color-black.svg" alt="Logo" width={80} />

         <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
               <Step key={label}  sx={{ minWidth: '1rem' }}>
                  <StepLabel
                     icon={
                        index < activeStep ? (
                           <Check sx={{ color: 'white' }} />
                        ) : (
                           <Circle
                              sx={{
                                 color:
                                    index === activeStep
                                       ? 'white'
                                       : 'var(--color-secondary-lighter)',
                              }}
                           />
                        )
                     }
                  >
                     {label}
                  </StepLabel>
               </Step>
            ))}
         </Stepper>
      </Container>
   );
};

export default CustomHeader;
