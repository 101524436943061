import styled from 'styled-components';

import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

/**Container dos elementos disponíveis para seleção (formas de pagamento genéricas (cartão, boleto...)) */
export const StyledRadioGroup = styled(RadioGroup)`
   padding: 0 1rem;

   display: flex;
   flex-direction: row !important;
`;

/**Elementos disponíveis para seleção (formas de pagamento genéricas (cartão, boleto...)) */
export const StyledGenericFormControlLabel = styled(FormControlLabel)`
   padding: 0 0.25rem;

   display: flex;
   flex-direction: column;

   border: ${({ checked }: { checked: boolean }) =>
      `2px solid ${checked ? 'var(--color-primary)' : 'var(--color-secondary)'}}`};
   border-radius: 0.25rem;

   & p {
      margin-top: -0.25rem;

      font-size: 0.75rem;
   }

   & svg:not(.checkedIcon) {
      width: 2.5rem;
      height: 2.5rem;

      margin: -0.5rem 0;
   }

   & .checkedIcon {
      width: 1rem;
      height: 1rem;

      position: absolute;
      top: -0.35rem;
      right: -0.75rem;

      border-radius: 50%;

      color: white;
      background: var(--color-primary);

      z-index: 100;
   }

   & span {
      &.Mui-checked {
         color: rgba(0, 0, 0, 0.6);
      }
   }
`;

/**Elementos disponíveis para seleção (formas de pagamento específicas (à vista, parcelado...)) */
export const StyledSpecificFormControlLabel = styled(FormControlLabel)`
   & p {
      margin-left: -0.5rem;

      font-size: 0.7rem;
   }

   & span {
      &.Mui-checked {
         color: var(--color-primary);
      }
   }
`;
