import styled from 'styled-components';

import Card from '@mui/material/Card';

/**Container geral */
export const StyledCard = styled(Card)`
   margin: 0.5rem;

   & .couponContainer {
      display: grid;
      grid-template-columns: 1fr 0.25fr;

      & .MuiInputBase-root {
         height: 100%;
      }

      & button {
         margin-left: 0.5rem;
      }
   }
`;
