export enum ProductTypesIds {
  MBA = "eeab68e206a1f93d29eb8903b3b18bf2",
  MBA_ASSINCRONO = "85e42d0162895f044e92f6f25529254d",
  MBA_IA = "7dddb32f6bd23bcddaccb0254dd873f8",
  ASSINATURA = "c5d88545973bb9108c108b72ab918570",
  BOOTCAMP = "c35ebb7bdeb8fcd82584f92bd3507058",
}

export enum ProductIds {
  BOOTCAMP_PASS_MENSAL = "094acad52ed6f12daab627ccfdae7292",
  BOOTCAMP_PASS_TRIMESTRAL = "8b2c7152913e01dceb094c070a27ac4c",
  BOOTCAMP_PASS_SEMESTRAL = "d1335574c4ba1cb1f67173f6579240ef",
  BOOTCAMP_PASS_ANUAL = "31ace9da1415f4227591ab57f459ca32",
}
